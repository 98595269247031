import {
  isLanguageSelectionEnabled,
  isPRODUCTION,
  isWebViewBuild,
} from '@src/constent'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationsEN from './en/en.json'
import translationsPT from './pt-br/pt-br.json'

// import translationsHE from './he/he.json'
// import translationsAR from './ar/ar.json'

const resources = {
  en: {
    translation: translationsEN,
  },
  'pt-BR': {
    translation: translationsPT,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: isLanguageSelectionEnabled() && !isPRODUCTION, // Enable this for debugging
    resources: resources,
    ns: ['translation'],
    defaultNS: ['translation'],
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: isWebViewBuild
        ? ['localStorage', 'cookie']
        : ['localStorage', 'cookie'],
      lookupQuerystring: 'lang',
      caches: ['localStorage', 'cookie'],
    },
  })

export default i18n
