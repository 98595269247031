import { handleEventCapture } from '@utils/sentryEventCapture'
import Axios from 'axios'

import { serverUrl1, serverUrl2, serverUrl4 } from '../../constent'
import {
  fetchWithAllErrorHandle,
  GET_FE_CAHCE_KEY,
  getAccessToken,
} from './helper'

export const getKycDetails_v4 = async () => {
  const url = `${serverUrl4}/kyc/profile/`
  const Authorization = getAccessToken()

  const result = await fetchWithAllErrorHandle
    .url(url)
    .auth(Authorization)
    .get()
    .json((json) => json)
    .catch((error) => {
      handleEventCapture(
        url,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  if (
    result instanceof Error ||
    !result ||
    typeof result?.statusCode === 'number'
  ) {
    throw result || new Error('Something went wrong')
  }

  return result as any
}

export const AddKycTaxIdForm = async (params: any) => {
  const url = `${serverUrl2}/kyc/profile/`
  const Authorization = getAccessToken()
  const result = await fetchWithAllErrorHandle
    .url(url)
    .auth(Authorization)
    .post(params)
    .json((json) => json)
    .catch((error) => {
      handleEventCapture(
        url,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  if (
    result instanceof Error ||
    !result ||
    typeof result?.statusCode === 'number'
  ) {
    throw result || new Error('Something went wrong')
  }
  return result
}

export const EditKycTaxIdForm = async (params: any) => {
  const url = `${serverUrl2}/kyc/profile/edit/`
  const Authorization = getAccessToken()
  const result = await fetchWithAllErrorHandle
    .url(url)
    .auth(Authorization)
    .post(params)
    .json((json) => json)
    .catch((error) => {
      handleEventCapture(
        url,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  if (
    result instanceof Error ||
    !result ||
    typeof result?.statusCode === 'number'
  ) {
    throw result || new Error('Something went wrong')
  }
  return result
}

export const getKycTaxIdUploadUrl = async () => {
  const url = `${serverUrl2}/kyc/document/upload/TaxID/presignedURL/?fe_cahche_key=${GET_FE_CAHCE_KEY()}`
  const Authorization = getAccessToken()

  const result = await fetchWithAllErrorHandle
    .url(url)
    .auth(Authorization)
    .get()
    .json((json) => json)
    .catch((error) => {
      handleEventCapture(
        url,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  if (
    result instanceof Error ||
    !result ||
    typeof result?.statusCode === 'number'
  ) {
    throw result || new Error('Something went wrong')
  }
  return result
}

export const putKycTaxIdUpload = async (params: {
  url: string
  thumbnail: Blob
}) => {
  const result = await Axios.put(params.url, params.thumbnail, {
    headers: {
      'Content-Type': params.thumbnail.type,
    },
  })
  console.log(`🚀 ~ file: kycV3ApiRequests.ts:122 ~ result:`, result)
  if (result.status === 200) {
    // Success, we make final API Call
    const url = `${serverUrl2}/kyc/document/upload/TaxID/`
    const Authorization = getAccessToken()
    const result = await fetchWithAllErrorHandle
      .url(url)
      .auth(Authorization)
      .post({})
      .json((json) => json)
      .catch((error) => {
        handleEventCapture(
          url,
          error?.message ? error?.message : 'Failed to Fetch Api'
        )
        return error
      })
    console.log(`🚀 ~ file: kycV3ApiRequests.ts:128 ~ result:`, result)
    if (
      result instanceof Error ||
      !result ||
      typeof result?.statusCode === 'number'
    ) {
      throw result || new Error('Something went wrong')
    }
    return result
  } else {
    throw result.data || new Error('Something went wrong')
  }
}

export const addBankAccountBrazil = async (params: any) => {
  const url = `${serverUrl4}/kyc/bank_account/add/`
  const Authorization = getAccessToken()

  const result = await fetchWithAllErrorHandle
    .url(url)
    .auth(Authorization)
    .post(params)
    .json((json) => json)
    .catch((error) => {
      handleEventCapture(
        url,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  if (
    result instanceof Error ||
    !result ||
    typeof result?.statusCode === 'number'
  ) {
    throw result || new Error('Something went wrong')
  }
  return result
}

export const editBankAccountBrazil = async (params: any) => {
  const url = `${serverUrl1}/kyc/bank_account/update/`
  const Authorization = getAccessToken()
  const result = await fetchWithAllErrorHandle
    .url(url)
    .auth(Authorization)
    .patch(params)
    .json((json) => json)
    .catch((error) => {
      handleEventCapture(
        url,
        error?.message ? error?.message : 'Failed to Fetch Api'
      )
      return error
    })
  if (
    result instanceof Error ||
    !result ||
    typeof result?.statusCode === 'number'
  ) {
    throw result || new Error('Something went wrong')
  }
  return result
}
