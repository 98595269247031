import { useTranslation } from 'react-i18next'

import {
  DEFAULT_LANGUAGE,
  LANGUAGE_PRESET,
  SUPPORTED_LANGUAGE,
} from './constants'

export const useRtlTranslation = () => {
  const { i18n } = useTranslation()
  const lang = i18n.resolvedLanguage
  return lang === 'ar'
}

export const parseSelectedLanguage = (language?: string): LANGUAGE_PRESET => {
  // Supported Languages are  ['en', 'pt-BR']
  if (language && SUPPORTED_LANGUAGE.includes(language)) {
    return language as LANGUAGE_PRESET
  } else {
    return DEFAULT_LANGUAGE
  }
}
